@import '../../styles/abstract.scss';

[class*="no-webp-img"] {
	.default {
		background-image: url('../../#{$img-path}/pro-user-cta-banner-bg.png');
	}
}

[class*="all-img"] {
	.default {
		background-image: url('../../#{$img-path}/pro-user-cta-banner-bg.webp');
	}
}

.default {
	background-position: bottom center;
	background-size: cover;
	width: 100%;
	padding: 60px 120px;
	padding: 70px 0;
	color: $white;

	@include media-below(xs) {
		text-align: center;
	}

	h2, p, .btn {
		color: inherit;
	}

	h2 {
		font-weight: $font-medium;

		strong {
			padding-left: 8px;
			font-weight: $font-bold;
		}
	}

	p {
		font-size: 17px;
		line-height: 24px;
		margin: 20px 0 40px 0;
		max-width: 60%;

		@include phone() {
			max-width: 100%;
		}
	}
}

.buttons {
	@include media-below(xs) {
		@include flexbox();
		@include flex-direction(column);
		max-width: 260px;
		margin: 0 auto;
	}

	button {
		@include media-above(xs) {
			margin-right: 30px;
		}

		+ a {
			margin-top: 20px;
		}
	}
}
