@import '../../styles/abstract.scss';

[class*="no-webp-img"] {
	.default {
		@include background-image('hero.png');
	}
}

[class*="all-img"] {
	.default {
		@include background-image('hero-1.webp');
	}
}

.default {
	height: 750px;
	max-height: 100vh;
	color: $white;
	@include flexbox();
	@include align-items(center);
	position: relative;

	[class*="container"] {
		max-width: 1000px;

		@include media-below(sm) {
			margin-top: 40px;
		}
	}

	@include media-below(lg) {
		height: 600px;
	}

	@include media-below(md) {
		background-position: 51%;
		height: 700px;
	}

	.overlay {
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		background: transparent linear-gradient(180deg, #2D3446 0%, #090A0E00 100%) 0% 0% no-repeat padding-box;
		opacity: 0.5;
		z-index: 0;
	}

	.textWrapper {
		h1 {
			@include h1();
			color: $white;
		}

		> div {
			width: 100%;
		}

		h1 + p {
			margin-bottom: 55px;
			font-size: 24px;
			line-height: 40px;
		}
	}

	.heroWrapper {
		@include flexbox();
		@include flex-direction(column);
		@include align-items(flex-start);

		> a {
			color: $white;
			margin-top: 40px;
			text-decoration: underline;
			@include order(2);

			@include media-below(md) {
				margin-top: 30px;
			}
		}
	}

	@include media-below(sm) {
		.textWrapper, .textWrapper + [class*="search-filters_hero"] {
			@include flexbox();
			@include flex-direction(column);
		}

		.textWrapper {
			@include order(3);
			text-align: left;
			margin-top: 30px;

			h1 {
				font-size: 40px;
				line-height: 49px;

				+ p {
					font-size: 20px;
					line-height: 30px;
					font-weight: $font-regular;

					@include media-below(md) {
						margin-top: 10px;
					}
				}
			}
		}

		[class*="search-filters_hero"] {
			@include order(1);
			margin-top: 30px;
		}
	}
}
